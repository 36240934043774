.MenuButton {
    background-color: #70002D !important;
}

.container {
    position: absolute;
    top: 10px;
    right: 10px;
}


/* .optionButtonsContainer {
    position: absolute;
    top: 10px;
    right: 10px;
  }

.optionButtons {
    background-color: #70002D !important;
    width: 220px;
    display: block !important;
    margin-bottom: 10px !important;
} */