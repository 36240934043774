.tableButtonGrid {
  margin-bottom: 10px !important;
  margin-right: 10px !important;
  height: 35px;
  background-color: #70002d !important;
}

.getResultsButton {
  background-color: #70002d !important;
  margin-left: auto !important;
  margin-bottom: 10px !important;
  height: 35px;
  float: right;
  margin-right: 10px;
}

.highlighted-row {
  background-color: rgba(0, 189, 113, 0.2) !important;
}

.highlighted-row-not-running {
  background-color: rgba(50, 137, 198, 0.1) !important;
}

.other-rows {
  background-color: rgba(0, 0, 0, 0) !important;
}

.mainDiv {
  height: 410px;
  width: 98%;
  margin-bottom: 90px;
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 50px;
  bottom: 10px;
  position: relative;
}

.CharCount {
  color: grey;
  font-size: 13px;
}
