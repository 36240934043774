.mainMoreThanSix {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 40px;
    overflow-y: auto;
    padding-bottom: 30px;
}

.centerMoreThanSix {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.main {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 40px;
    overflow-y: auto;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.centerDecision {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.choiceButton {
    background-color: #70002D !important;
    margin-bottom: 20px !important;
    width: 200px;
    height: 50px;
    font-size: 20px !important;
}

.choiceButtonBlankt {
    background-color: #808080 !important;
    margin-bottom: 20px !important;
    width: 200px;
    height: 50px;
    font-size: 20px !important;
}


.choiceButton2 {
    background-color: #70002D !important;
    margin-bottom: 20px !important;
    min-width: 200px !important;
    min-height: 50px !important;
    max-width: 80vw !important;
    max-height: 40vh !important;
    white-space: normal !important;
    overflow: visible !important;
    padding: 10px !important;
    box-sizing: border-box !important;
    text-transform: none !important;
    font-size: 20px !important;
}

@media (max-width: 600px) {
    .choiceButton2 {
        font-size: 13px !important;
    }
}

.choiceButton2Blankt {
    background-color: #808080 !important;
    margin-bottom: 20px !important;
    font-size: 16px !important;
    width: 200px;
    height: 50px;
    font-size: 20px !important;
}

.select {
    width: 200px !important;
    height: 50px !important;
    margin-top: 10px !important;
}

.sendVoteButton {
    background-color: #70002D !important;
    margin-top: 20px !important;
    width: 200px !important;
    height: 50px;
}

.title {
    margin-bottom: 20px;
    font-size: 30px;
    text-align: center;
    max-width: 80vw;
}

.changeVoteButton {
    background-color: #70002D !important;
    margin-bottom: 20px !important;
    width: 200px;
    height: 50px;
    font-size: 15px !important;
}