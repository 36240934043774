.App{
    min-width: 1440px;
    min-height: 820px;
    overflow: auto;
    position: relative;
}

.text {
    margin-top: 320px;
    color: #70002D
}

.frame {
    background-color: white;
    width: 500px;
    margin: auto;
    margin-top: 20px;
    height: auto;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    /* center the elements vertically */
    align-items: center;
    /* center the elements horizontally */
}

.button {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    width: 45%;
    background-color: #70002D !important;
}

.startElection {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    width: 45%;
    background-color: green !important;
}

.endElection {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    width: 45%;
    background-color: #70002D !important;

}

.nextElection {
    margin-bottom: 10px !important;
    width: 45%;
    background-color: green !important;
}

.previousElection {
    margin-bottom: 20px !important;
    width: 45%;
    background-color: green !important;
}

/* .optionButtonsContainer {
    position: absolute;
    top: 10px;
    right: 10px;
  }

.optionButtons {
    background-color: #70002D !important;
    width: 220px;
    display: block !important;
    margin-bottom: 10px !important;
} */

.loading {
    color: #70002D !important;
    position: absolute;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px); /* Adjust the blur strength as needed */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  